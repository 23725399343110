<template>
  <v-layout class="ma-0" align-center row justify-center wrap>
    <PoliticasProteccionDatos
      v-on:handleClose="
        dialogSee = false;
      "
      :modal="dialogSee"

    ></PoliticasProteccionDatos>
    <!-- PARTE 1 -->
    <v-flex xs8 fill-height>
      <v-card height="100%" class="back1 mx-auto" style="z-index: 3">
        <v-layout row class="pa-4">
          <v-flex xs1 sm2 md1 xl1 class="pa-4">
            <v-img
              src="./../assets/media/rpg/gobernacion-san-andres.png"
              width="59"
              height="67"
              max-width="59"
              max-height="67"
            ></v-img>
          </v-flex>
          <v-flex xs11 sm10 md11 xl11 class="pa-4">
            <v-img
              src="./../assets/media/rpg/min-tic.png"
              max-width="292"
              max-height="55"
            ></v-img>
          </v-flex>
        </v-layout>
        <v-card-title class="text-center font-weight-bold display-1 align">
          <!-- LOGIN -->

          <loading
            color="blue"
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="fullPage"
            loader="spinner"
          ></loading>
          <v-container fluid fill-height>
            <v-layout row align-center justify-center>
              <v-flex xs6 offset-xs12>
                <v-hover>
                  <v-card
                    height="460"
                    width="380"
                    slot-scope="{ hover }"
                    :class="`elevation-${hover ? 12 : 4}`"
                  >
                    <v-layout align-center justify-center column>
                      <v-img
                        :src="require('@/assets/media/rpg/logo-rgp-login.png')"
                        width="259"
                        height="81"
                        alt="RPG"
                        class="mt-4"
                      ></v-img>

                      <p
                        class="ma-2 pa-2 text-center headline font-weight-bold"
                      >
                        Ingrese a su cuenta
                      </p>
                      <v-container>
                        <v-form
                          @submit.prevent="handleLoginFormSubmit"
                          ref="form_login"
                          lazy-validation
                          v-model="valid"
                          class="vld-parent"
                        >
                          <v-text-field
                            prepend-icon="fa fa-user"
                            label="Correo Electronico"
                            v-model="login.email"
                            :rules="emailRules"
                            required
                          ></v-text-field>

                          <v-text-field
                            @click:prepend="seePassword"
                            :prepend-icon="prependIcon"
                            label="Contraseña"
                            v-model="login.password"
                            :rules="passwordRules"
                            :type="passwordTypeField"
                            v-on:keyup="changeIcon"
                            v-on:keyup.enter="handleLoginFormSubmit"
                          ></v-text-field>
                          <v-layout swap>
                            <!-- <v-flex xs6>
                              <v-checkbox v-model="login.remember" label="Recordar Cuenta" required></v-checkbox>
                            </v-flex>
                            <v-flex xs6 class="mt-4 d-flex justify-end">
                               <div>
                                <div class="overline mt-2 ml-4">¿Olvidó su contraseña?</div>
                              </div>
                            </v-flex> -->
                          </v-layout>

                          <v-btn
                            @click.prevent="handleLoginFormSubmit"
                            dark
                            color="blue"
                            rounded
                            x-large
                            autofocus 
                            ref="input"
                            >Iniciar Sesión</v-btn
                          >

                          <!--v-alert type="error" :disabled="!valid" :value="thereIsError">I'm an error alert.</v-alert-->
                        </v-form>
                      </v-container>
                    </v-layout>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>

          <!-- END LOGIN -->
        </v-card-title>
        <v-card-text class="font-weight-medium">
          Herramientas para el mejoramiento de la Gobernabilidad y Transparencia
          en la gestión de los recursos hidrobiológicos y pesqueros en la
          reserva de biosfera SeaFlower 2016-2020 en San Andrés, Providencia y
          Santa Catalina.
          <a @click="dialogSee=true"
          > Politicas Y Privacidad
          </a>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- PARTE 2 -->
    <v-flex xs4 fill-height class="back2"> </v-flex>
  </v-layout>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import { loginUrl, getAuthUserUrl } from "./../config";
import { clientId, clientSecret } from "./../env";
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    Loading,
    PoliticasProteccionDatos: () => import('./PoliticasProteccionDatos')
  },
  data: () => ({
    dialogSee: false,
    isLoading: false,
    fullPage: true,
    /*loader: null,
    loading: false,*/
    fab: false,
    hidden: false,
    tabs: null,
    valid: false,
    prependIcon: 'fa fa-lock',
    passwordTypeField: 'password',
    login: {
      email: "",
      password: "",
      remember: true,
      //url: encodeURI(window.location.href)
    },
    passwordRules: [
      (v) => !!v || "La contraseña es requerida",
      (v) => (v || "").indexOf(" ") < 0 || "No se permiten espacios",
      (v) =>
        (v && v.length >= 5) || "La contraseña debe tener 6 o más caracteres",
    ],
    emailRules: [
      (v) => !!v || "El email es requerido",
      (v) => (v || "").indexOf(" ") < 0 || "No se permiten espacios",
      (v) => /.+@.+/.test(v) || "El email debe ser válido",
    ],
  }),
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { color: "success", icon: "mdi-plus" };
        case "two":
          return { color: "red", icon: "edit" };
        case "three":
          return { color: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
  },
  methods: {
    ...mapActions(["validateToken"]),

    seePassword()
    {
      if(this.passwordTypeField == 'text')
      {
        this.passwordTypeField = 'password';
        this.prependIcon = 'fas fa-eye';
      }
      else
      {
        this.passwordTypeField = 'text';
        this.prependIcon = 'fas fa-eye-slash';
      }
    },

    changeIcon()
    {
      if(this.login.password != '')
      {
        this.prependIcon = 'fas fa-eye';
      }
    },

    handleLoginFormSubmit() {
      //this.loading = true;
      //

      if (this.$refs.form_login.validate()) {
        // this.isLoading = true;

        const postData = {
          grant_type: "password",
          client_id: clientId,
          client_secret: clientSecret,
          username: this.login.email,
          password: this.login.password,
          scope: "general",
        };

        const authUser = {};

        this.axios
          .post(loginUrl, postData)
          .then((response) => {
            if (response.status === 200) {
              authUser.access_token = response.data.access_token;
              authUser.refresh_token = response.data.refresh_token;
              window.localStorage.setItem("authUser", JSON.stringify(authUser));

              this.axios.post(getAuthUserUrl).then((response) => {
                window.localStorage.setItem(
                  "authUser",
                  JSON.stringify({...authUser, ...response.data.user})
                );
                window.location.href = "/dashboard";
              });
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$toasted.show("Correo o Contraeña Incorrectos", {
                theme: "bubble",
                position: "bottom-right",
                duration: 4000,
                action: {
                  text: "Cancel",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  },
                },
              });
            }
          });
      } else {
        this.isLoading = false;
      }
    },
  }
};
</script>

<style scoped>
.back1 {
  background-image: url("./../assets/media/rpg/bg-login.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.back2 {
  background-image: url("./../assets/media/rpg/fish-bg-login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* This is for documentation purposes and will not be needed in your application */
#lateral .v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
    background-color: blueviolet;
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
    background-color: blueviolet;
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
